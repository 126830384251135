// Nav Action Types
export const NavActionTypes = {
  SET_IS_NAV_LOADING: 'SET_IS_NAV_LOADING',
  SET_IS_LEFT_NAV_OPEN: 'SET_IS_LEFT_NAV_OPEN',
  SET_NATIONS: 'SET_NATIONS',
  SET_SELECTED_NATION: 'SET_SELECTED_NATION',
  SET_REGIONS: 'SET_REGIONS',
  SET_SELECTED_REGION: 'SET_SELECTED_REGION',
  SET_AREAS: 'SET_AREAS',
  SET_SELECTED_AREA: 'SET_SELECTED_AREA',
  SET_TERRITORIES: 'SET_TERRITORIES',
  SET_SELECTED_TERRITORY: 'SET_SELECTED_TERRITORY',
  SET_TOWNS: 'SET_TOWNS',
  SET_SELECTED_TOWN: 'SET_SELECTED_TOWN',
  SET_ROUTES: 'SET_ROUTES',
  SET_SELECTED_ROUTE: 'SET_SELECTED_ROUTE',
  SET_DISTRIBUTION_HOUSES: 'SET_DISTRIBUTION_HOUSES',
  SET_OUTLETS: 'SET_OUTLETS',
  SET_SELECTED_OUTLET: 'SET_SELECTED_OUTLET',
  SET_IS_OUTLETS_LIST_EXPANDED: 'SET_IS_OUTLETS_LIST_EXPANDED',
  SET_IS_SR_ROUTE_ON: 'SET_IS_SR_ROUTE_ON',
  SET_LATE_ORDER_OUTLETS: 'SET_LATE_ORDER_OUTLETS',
  SET_FAST_ORDER_OUTLETS: 'SET_FAST_ORDER_OUTLETS',
  SET_DECLINING_OUTLETS: 'SET_DECLINING_OUTLETS',
  SET_NON_COMPLIANT_OUTLETS: 'SET_NON_COMPLIANT_OUTLETS',
  SET_OUTLETS_FILTER_OPTIONS: 'SET_OUTLETS_FILTER_OPTIONS',
  SET_SELECTED_OUTLETS_FILTER_OPTION: 'SET_SELECTED_OUTLETS_FILTER_OPTION',
  SET_IS_BKOI_OUTLETS_ON: 'SET_IS_BKOI_OUTLETS_ON',
  SET_IS_FIXED_OUTLETS_ON: 'SET_IS_FIXED_OUTLETS_ON',
  SET_IS_FIXED_ROUTES_ON: 'SET_IS_FIXED_ROUTES_ON',
  SET_BKOI_OUTLETS: 'SET_BKOI_OUTLETS',
  SET_FIXED_OUTLETS: 'SET_FIXED_OUTLETS',
  SET_FIXED_ROUTES: 'SET_FIXED_ROUTES',
  SET_FIXED_ROUTES_DATA: 'SET_FIXED_ROUTES_DATA',
  SET_IS_BKOI_CLUSTERS_ON: 'SET_IS_BKOI_CLUSTERS_ON',
  SET_BKOI_CLUSTERS: 'SET_BKOI_CLUSTERS',
  SET_IS_TOWN_OUTLETS_ON: 'SET_IS_TOWN_OUTLETS_ON',
  SET_IS_BKOI_TOWN_OUTLETS_ON: 'SET_IS_BKOI_TOWN_OUTLETS_ON',
  SET_IS_ROUTES_LAYER_VISIBLE: 'SET_IS_ROUTES_LAYER_VISIBLE',
  SET_IS_ALL_DISTRIBUTION_HOUSES_ON: 'SET_IS_ALL_DISTRIBUTION_HOUSES_ON',
  SET_ALL_DISTRIBUTION_HOUSES: 'SET_ALL_DISTRIBUTION_HOUSES'
}

// Stat Action Types
export const StatActionTypes = {
  SET_IS_STAT_LOADING: 'SET_IS_STAT_LOADING',
  SET_START_DATE: 'SET_START_DATE',
  SET_END_DATE: 'SET_END_DATE',
  SET_SELECTED_ROUTE_STATS_TYPE: 'SET_SELECTED_ROUTE_STATS_TYPE',
  SET_SR_LIST: 'SET_SR_LIST',
  SET_SELECTED_SR: 'SET_SELECTED_SR',
  SET_SELECTED_TOWN_STATS_TYPE: 'SET_SELECTED_TOWN_STATS_TYPE',
  SET_DH_LIST: 'SET_DH_LIST',
  SET_SELECTED_DH: 'SET_SELECTED_DH',
  SET_BRANDS: 'SET_BRANDS',
  SET_SELECTED_BRAND: 'SET_SELECTED_BRAND',
  SET_SKU: 'SET_SKU',
  SET_SELECTED_SKU: 'SET_SELECTED_SKU',
  SET_STATS: 'SET_STATS',
  SET_SELECTED_STAT_TAB: 'SET_SELECTED_STAT_TAB',
  SET_COMPARISON_START_DATE: 'SET_COMPARISON_START_DATE',
  SET_COMPARISON_END_DATE: 'SET_COMPARISON_END_DATE',
  SET_SELECTED_COMPARISON_BRAND: 'SET_SELECTED_COMPARISON_BRAND',
  SET_SELECTED_COMPARISON_SKU: 'SET_SELECTED_COMPARISON_SKU',
  SET_SELECTED_COMPARE_OPTION_1: 'SET_SELECTED_COMPARE_OPTION_1',
  SET_SELECTED_COMPARE_OPTION_2: 'SET_SELECTED_COMPARE_OPTION_2',
  SET_SELECTED_COMPARE_STATS_1: 'SET_SELECTED_COMPARE_STATS_1',
  SET_SELECTED_COMPARE_STATS_2: 'SET_SELECTED_COMPARE_STATS_2',
  SET_SR_ORDERS: 'SET_SR_ORDERS',
  SET_SR_ORDER_COLUMNS: 'SET_SR_ORDER_COLUMNS',
  SET_IS_SR_ORDERS_LAYER_ON: 'SET_IS_SR_ORDERS_LAYER_ON',
  SET_SR_VISITED_OUTLETS: 'SET_SR_VISITED_OUTLETS',
  SET_SELECTED_STATS_COMPARISON_OPTIONS: 'SET_SELECTED_STATS_COMPARISON_OPTIONS',
  SET_SELECTED_STATS_COMPARISON_FIELD_OPTIONS: 'SET_SELECTED_STATS_COMPARISON_FIELD_OPTIONS',
  SET_STATS_COMPARISON_DATA: 'SET_STATS_COMPARISON_DATA',
  SET_SR_ORDER_CLUSTERS: 'SET_SR_ORDER_CLUSTERS'
}

// Map Action Types
export const MapActionTypes = {
  SET_IS_MAP_LOADING: 'SET_IS_MAP_LOADING',
  SET_COLOR_BY_OPTIONS: 'SET_COLOR_BY_OPTIONS',
  SET_SELECTED_COLOR_BY: 'SET_SELECTED_COLOR_BY',
  SET_SELECTED_STRIKE_RATE: 'SET_SELECTED_STRIKE_RATE',
  SET_CHANNELS: 'SET_CHANNELS',
  SET_SELECTED_CHANNEL: 'SET_SELECTED_CHANNEL'
}

// Trace Action Types
export const TraceActionTypes = {
  SET_IS_TRACE_LOADING: 'SET_IS_TRACE_LOADING',
  SET_IS_TRACE_ON: 'SET_IS_TRACE_ON',
  SET_TRACE_USERS: 'SET_TRACE_USERS',
  UPDATE_TRACE_USERS: 'UPDATE_TRACE_USERS'
}

// Auth Action Types
export const AuthActionTypes = {
  SET_IS_AUTHENTICATED: 'SET_IS_AUTHENTICATED',
  SET_IS_AUTH_VALIDATING: 'SET_IS_AUTH_VALIDATING',
  SET_EMAIL_ID: 'SET_EMAIL_ID',
  SET_PASSWORD: 'SET_PASSWORD',
  SET_TOKEN: 'SET_TOKEN',
  SET_USER: 'SET_USER',
  SET_AUTH_ERROR: 'SET_AUTH_ERROR'
}

// Feature Control Action Types
export const FeatureControlActionTypes = {
  SET_IS_NATION_ON: 'SET_IS_NATION_ON',
  SET_IS_REGION_ON: 'SET_IS_REGION_ON',
  SET_IS_AREA_ON: 'SET_IS_AREA_ON',
  SET_IS_TERRITORY_ON: 'SET_IS_TERRITORY_ON',
  SET_IS_TOWN_ON: 'SET_IS_TOWN_ON',
  SET_IS_ROUTE_ON: 'SET_IS_ROUTE_ON'
}

// Socket Action Types
export const SocketActionTypes = {
  SET_SR_POSITION_DATA: 'SET_SR_POSITION_DATA',
  SET_SR_ORDER_DELAY_DATA: 'SET_SR_ORDER_DELAY_DATA'
}

// Configs Action Types
export const ConfigsActionTypes = {
  SET_VISIT_DISTANCE_THRESHOLD: 'SET_VISIT_DISTANCE_THRESHOLD',
  SET_FAST_ORDER_DURATION: 'SET_FAST_ORDER_DURATION',
  SET_LATE_ORDER_THRESHOLD_TIME: 'SET_LATE_ORDER_THRESHOLD_TIME'
}