import { useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

// Import Components
import { Box } from '@mui/material'
import StyledSwitch from '../common/StyledSwitch'

// Import Actions & Methods
import { getFixedRoutes, setIsFixedRoutesOn, transformRowObjectListToGeojson } from '../../redux/actions/navActions'
import { clearFixedRoutesFromMap, loadFixedRoutesToMap } from '../../redux/actions/mapActions'

const FixedRoutesControl = ({ dispatch, selectedTown, selectedRoute, fixedRoutesData, isFixedRoutesOn }) => {

  useEffect(() => {
    if (isFixedRoutesOn) {
      if (selectedTown?.value !== 'All' && selectedTown?.value !== 'None') {
        // Load Barikoi Outlets
        dispatch(getFixedRoutes({ town_id: selectedTown?.id ?? '' }))
      }

    } else {
      // Clear Fixed Routes
      dispatch(clearFixedRoutesFromMap())
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFixedRoutesOn, selectedTown])

  useEffect(() => {
    if (isFixedRoutesOn && fixedRoutesData && selectedRoute) {
      // Filter Data
      const filteredFixedRoutes = fixedRoutesData.filter(r => r.code === selectedRoute?.code)

      // Load Fixed Routes To Map
      const routeGeojson = transformRowObjectListToGeojson(filteredFixedRoutes)
      dispatch(loadFixedRoutesToMap(routeGeojson))
    } else {
      console.log('false')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFixedRoutesOn, fixedRoutesData, selectedRoute])

  // On Fixed Routes Control Change
  const _onFixedRoutesControlChange = (e, value) => {
    dispatch(setIsFixedRoutesOn(value))
  }

  return (
    <Box>
      <StyledSwitch
        label='Fixed Routes'
        checked={isFixedRoutesOn}
        value={isFixedRoutesOn}
        onChange={_onFixedRoutesControlChange}
      />
    </Box>
  )
}

// Prop Types
FixedRoutesControl.propTypes = {
  selectedTown: PropTypes.object,
  selectedRoute: PropTypes.object,
  fixedRoutesData: PropTypes.object,
  isFixedRoutesOn: PropTypes.bool,
  dispatch: PropTypes.func
}

FixedRoutesControl.defaultProps = {
  selectedTown: null,
  selectedRoute: null,
  fixedRoutesData: null,
  isFixedRoutesOn: false,
  dispatch: () => null
}

const mapStateToProps = state => ({
  selectedTown: state?.nav?.selectedTown ?? null,
  selectedRoute: state?.nav?.selectedRoute ?? null,
  fixedRoutesData: state?.nav?.fixedRoutesData ?? null,
  isFixedRoutesOn: state?.nav?.isFixedRoutesOn ?? false
})

const mapDispatchToProps = dispatch => ({ dispatch })

export default connect(mapStateToProps, mapDispatchToProps)(FixedRoutesControl)