import { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

// Import Components
import { Box, Menu } from '@mui/material'
import { FilterList } from '@mui/icons-material'
import StyledButton from '../common/StyledButton'
import FilterByStrikeRate from './FilterByStrikeRate'
import FilterByChannel from './FilterByChannel'
import FilterByClp from './FilterByClp'
import FilterByGrowthPercentage from './FilterByGrowthPercentage'
import FilterByTargetAchievedPercentile from './FilterByTargetAchievedPercentile'

// Import Actions & Methods
import { getMinMaxValue } from '../../utils/utils'

const Filters = ({ selectedRoute, selectedTown, outlets }) => {
  const [ accMenuAnchorEl, setAccMenuAnchorEl ] = useState(null)
  const [ clpOptions ] = useState([
    { value: 'HIGH', label: 'HIGH', clp: 'HIGH' },
    { value: 'MEDIUM', label: 'MEDIUM', clp: 'MEDIUM' },
    { value: 'LOW', label: 'LOW', clp: 'LOW' },
    { value: 'Other', label: 'Other', clp: 'Other' }
  ])
  const [ selectedClp, setSelectedClp ] = useState({ value: 'All', label: 'All' })
  const [ minMaxGrowthPercentage, setMinMaxGrowthPercentage ] = useState([ -100, 100 ])
  const [ growthPercentageRangeMarks, setGrowthPercentageRangeMarks ] = useState([{ value: 0, label: 0 }])
  const [ selectedGrowthPercentage, setSelectedGrowthPercentage ] = useState([ -100, 100 ])
  const [ selectedTargetAchievedPercentile, setSelectedTargetAchievedPercentile ] = useState([ 0, 100 ])
  const accMenuOpen = Boolean(accMenuAnchorEl)

  useEffect(() => {
    if(outlets?.length) {
      const minMaxGrowthPercentage = getMinMaxValue(outlets, 'growth_percentage', true)
      const growthPercentageRangeMarks = _getGrowthPercentageRangeMarks(minMaxGrowthPercentage, Math.ceil((minMaxGrowthPercentage[1] - minMaxGrowthPercentage[0]) / 6))
      setMinMaxGrowthPercentage(minMaxGrowthPercentage)
      setGrowthPercentageRangeMarks(growthPercentageRangeMarks)
    }

  }, [ outlets ])

  // Open Filters Menu
  const _openFiltersMenu = e => {
    setAccMenuAnchorEl(e.currentTarget)
  }

  // Close Filters Menu
  const _closeFiltersMenu = () => {
    setAccMenuAnchorEl(null)
  }

  // On Selected CLP Change
  const _onSelectedClpChange = (e, selectedClp) => {
    setSelectedClp(selectedClp)
  }

  // On Growth Percentage Change
  const _onGrowthPercentageChange = (e, selectedGrowthPercentage) => {
    setSelectedGrowthPercentage(selectedGrowthPercentage)
  }

  // On Target Achieved Percentile Change
  const _onTargetAchievedPercentileChange = (e, selectedTargetAchievedPercentile) => {
    setSelectedTargetAchievedPercentile(selectedTargetAchievedPercentile)
  }

  // Get Growth Percentage Range Marks
  const _getGrowthPercentageRangeMarks = (minMaxRangeValue, step) => {
    const marks = [{ value: minMaxRangeValue[ 0 ], label: minMaxRangeValue[ 0 ] }]
    for(let i = minMaxRangeValue[ 0 ] + step; i > minMaxRangeValue[ 0 ] && Math.ceil(i) < minMaxRangeValue[ 1 ]; i += step) {
      marks.push({
        value: Math.ceil(i),
        label: Math.ceil(i)
      })
    }

    marks.push({ value: minMaxRangeValue[ 1 ], label: minMaxRangeValue[ 1 ] })

    return marks
  }

  return (
    <Box sx={ containerStyles }>
      <StyledButton
        aria-controls={ accMenuOpen ? 'filters-menu' : undefined }
        aria-haspopup='true'
        aria-expanded={ accMenuOpen ? 'true' : undefined }
        variant='outlined'
        disableRipple={ true }
        endIcon={ <FilterList fontSize='small' /> }
        onClick={ _openFiltersMenu }
        sx={{
          ...styledButtonStyles,
          borderColor: accMenuOpen ? 'rgba(0, 0, 0, 0.87)' : 'rgba(0, 0, 0, 0.23)'
        }}
      >
        { 'Filters' }
      </StyledButton>

      <Menu
        anchorEl={ accMenuAnchorEl }
        id='filters-menu'
        open={ accMenuOpen }
        onClose={ _closeFiltersMenu }
        PaperProps={ menuPaperProps }
        transformOrigin={{ horizontal: 'left', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
      >
        { (selectedRoute?.value !== 'All' && selectedRoute?.value !== 'None' && selectedRoute?.value) ?
          (
            <Box width='100%' mt='1rem'>
              <FilterByGrowthPercentage
                minMaxGrowthPercentage={ minMaxGrowthPercentage }
                growthPercentageRangeMarks={ growthPercentageRangeMarks }
                selectedGrowthPercentage={ selectedGrowthPercentage }
                onGrowthPercentageChange={ _onGrowthPercentageChange }
              />
              <FilterByTargetAchievedPercentile
                selectedTargetAchievedPercentile={ selectedTargetAchievedPercentile }
                onTargetAchievedPercentileChange={ _onTargetAchievedPercentileChange }
              />
              <FilterByChannel />
              <FilterByClp
                clpOptions={ clpOptions }
                selectedClp={ selectedClp }
                onSelectedClpChange={ _onSelectedClpChange }
              />
            </Box>
          )
          :
          (
            <Box width='100%'>
              <FilterByStrikeRate />
              { (selectedTown?.value !== 'All' && selectedTown?.value !== 'None' && selectedTown?.value && outlets?.length) ?
                (
                  <>
                    <FilterByGrowthPercentage
                      minMaxGrowthPercentage={ minMaxGrowthPercentage }
                      growthPercentageRangeMarks={ growthPercentageRangeMarks }
                      selectedGrowthPercentage={ selectedGrowthPercentage }
                      onGrowthPercentageChange={ _onGrowthPercentageChange }
                    />
                    <FilterByChannel />
                  </>
                )
                :
                (
                  null
                )
              }
            </Box>
          )
        }
      </Menu>
    </Box>
  )
}

// JSS Styles
const containerStyles = {
  marginTop: '2px'
}

const styledButtonStyles = {
  color: 'rgba(0, 0, 0, 0.87)',
  fontSize: '0.7rem',
  fontWeight: 400,
  textTransform: 'none',
  '&:hover': {
    borderColor: 'rgba(0, 0, 0, 0.87)'
  },
  '&:focus': {
    borderColor: '#1976d2'
  }
}

const menuPaperProps = {
  elevation: 0,
  sx: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'stretch',
    minWidth: '240px',
    paddingX: '0.5rem',
    overflow: 'visible',
    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
    mt: 1,
    '&:before': {
      content: '""',
      display: 'block',
      position: 'absolute',
      top: 0,
      left: 14,
      width: 10,
      height: 10,
      bgcolor: 'background.paper',
      transform: 'translateY(-50%) rotate(45deg)',
      zIndex: 0
    }
  }
}

// Prop Types
Filters.propTypes = {
  selectedRoute: PropTypes.object,
  selectedTown: PropTypes.object,
  outlets: PropTypes.array
}

Filters.defaultProps = {
  selectedRoute: null,
  selectedTown: null,
  outlets: []
}

const mapStateToProps = state => ({
  selectedRoute: state?.nav?.selectedRoute ?? null,
  selectedTown: state?.nav?.selectedTown ?? null,
  outlets: state?.nav?.outlets ?? null
})

export default connect(mapStateToProps)(Filters)