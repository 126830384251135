import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import _isEqual from 'fast-deep-equal'
import { OUTLETS } from '../../App.config'

// Import Components
import { Box, Divider, Accordion, AccordionSummary, AccordionDetails, Typography } from '@mui/material'
import { ExpandMore } from '@mui/icons-material'
import StyledSelect from '../common/StyledSelect'
import StatsSelect from './StatsSelect'
import StyledToggleButtonGroup from '../common/StyledToggleButtonGroup'
import OutletList from './OutletList'

// Import Actions & Methods
import { setFilter } from 'kepler.gl/actions'
import { setSelectedNation, getRegions, setRegions, setSelectedRegion, getAreas, setAreas, setSelectedArea, getTerritories, setTerritories, setDistributionHouses, setSelectedTerritory, getTowns, setTowns, setSelectedTown, getRoutes, setRoutes, setSelectedRoute, getOutlets, setOutlets, setIsOutletsListExpanded, setSelectedOutletsFilterOption } from '../../redux/actions/navActions'
import { filterNations, filterRegions, clearRegionsFromMap, filterAreas, clearAreasFromMap, filterTerritories, clearTerritoriesFromMap, clearTownsFromMap, filterTowns, filterRoutes, clearRoutesFromMap, filterOutlets, clearOutletsFromMap, fitBoundsMap } from '../../redux/actions/mapActions'
import { datasetToRowObject } from '../../utils/mapUtils'

class LeftNavBody extends React.PureComponent {
  state = {
    tempOutlets: []
  }

  componentDidUpdate(prevProps) {
    const { dispatch, selectedNation, selectedRegion, selectedArea, selectedTerritory, selectedTown, selectedRoute, selectedOutletsFilterOption, selectedRouteStatsType, selectedTownStatsType, datasets } = this.props

    // If selectedNation Change in Props
    if (prevProps?.selectedNation?.value !== selectedNation?.value && selectedNation?.value) {
      this._onSelectedNationUpdate(selectedNation)
    }

    // If selectedRegion Change in Props
    if (prevProps?.selectedRegion?.value !== selectedRegion?.value && selectedRegion?.value) {
      this._onSelectedRegionUpdate(selectedRegion, selectedNation)
    }

    // If selectedArea Change in Props
    if (prevProps?.selectedArea?.value !== selectedArea?.value && selectedArea?.value) {
      this._onSelectedAreaUpdate(selectedArea, selectedRegion)
    }

    // If selectedTerritory Change in Props
    if (prevProps?.selectedTerritory?.value !== selectedTerritory?.value && selectedTerritory?.value) {
      this._onSelectedTerritoryUpdate(selectedTerritory, selectedArea)
    }

    // If selectedTown Change in Props
    if (prevProps?.selectedTown?.value !== selectedTown?.value && selectedTown?.value) {
      this._onSelectedTownUpdate(selectedTown, selectedTerritory)
    }

    // If selectedRoute Change in Props
    if (prevProps?.selectedRoute?.value !== selectedRoute?.value && selectedRoute?.value) {
      this._onSelectedRouteUpdate(selectedRoute, selectedTown)
    }

    // If selectedOutletsFilterOption Change in Props
    if (prevProps?.selectedOutletsFilterOption?.value !== selectedOutletsFilterOption?.value && selectedOutletsFilterOption?.value) {
      this._onSelectedOutletsFilterOptionUpdate(selectedOutletsFilterOption)
    }

    // If `selectedRouteStatsType` or `selectedTownStatsType` changes in props
    if (prevProps.selectedRouteStatsType !== selectedRouteStatsType || prevProps.selectedTownStatsType !== selectedTownStatsType) {
      dispatch(setSelectedOutletsFilterOption({ value: 'all-outlets', label: 'All Outlets' }))
    }

    // If Outlets cahnges in Kepler GL Dataset
    if (
      (!prevProps?.datasets[OUTLETS.DATA_ID] && datasets[OUTLETS.DATA_ID])
      || prevProps?.datasets[OUTLETS.DATA_ID]?.allData?.length !== datasets[OUTLETS.DATA_ID]?.allData?.length
      || prevProps?.datasets[OUTLETS.DATA_ID]?.filteredIndexForDomain?.length !== datasets[OUTLETS.DATA_ID]?.filteredIndexForDomain?.length
      || !_isEqual(prevProps?.datasets[OUTLETS.DATA_ID]?.allData, datasets[OUTLETS.DATA_ID]?.allData)
      || !_isEqual(prevProps?.datasets[OUTLETS.DATA_ID]?.filteredIndexForDomain, datasets[OUTLETS.DATA_ID]?.filteredIndexForDomain)
    ) {
      this.setState({ tempOutlets: datasets[OUTLETS.DATA_ID] ? datasetToRowObject(datasets[OUTLETS.DATA_ID], true) : [] })
    }
  }

  // On Nation Change
  _onNationChange = (e, selectedNation) => {
    const { dispatch } = this.props
    dispatch(setSelectedNation(selectedNation))
  }

  // On Region Change
  _onRegionChange = (e, selectedRegion) => {
    const { dispatch } = this.props
    const coordinates =  selectedRegion?.geometry?.coordinates
    if(coordinates){
      dispatch(fitBoundsMap(coordinates, 11))
    }
    dispatch(setSelectedRegion(selectedRegion))
  }

  // On Area Change
  _onAreaChange = (e, selectedArea) => {
    const { dispatch } = this.props

    const coordinates =  selectedArea?.geometry?.coordinates
    if(coordinates){
      dispatch(fitBoundsMap(coordinates, 11))
    }
    dispatch(setSelectedArea(selectedArea))
  }

  // On Territory Change
  _onTerritoryChange = (e, selectedTerritory) => {
    const { dispatch } = this.props

    const coordinates =  selectedTerritory?.geometry?.coordinates
    if(coordinates){
      dispatch(fitBoundsMap(coordinates, 11))
    }

    dispatch(setSelectedTerritory(selectedTerritory))
  }

  // On Town Change
  _onTownChange = (e, selectedTown) => {
    const { dispatch } = this.props

    const coordinates =  selectedTown?.geometry?.coordinates
    if(coordinates){
      dispatch(fitBoundsMap(coordinates, 11))
    }

    dispatch(setSelectedTown(selectedTown))
  }

  // On Route Change
  _onRouteChange = (e, selectedRoute) => {
    const { dispatch } = this.props

    const coordinates =  selectedRoute?.geometry?.coordinates
    if(coordinates){
      dispatch(fitBoundsMap(coordinates, 11.5))
    }

    dispatch(setSelectedRoute(selectedRoute))
  }

  // On Outlets List Toggle
  _onOutletsListToggle = (e, isOutletsListExpanded) => {
    const { dispatch } = this.props
    dispatch(setIsOutletsListExpanded(isOutletsListExpanded))
  }

  // On selectedNation Update in Props
  _onSelectedNationUpdate = selectedNation => {
    const { dispatch, startDate, endDate, selectedBrand, selectedSku } = this.props

    // Filter Nations Data
    dispatch(
      filterNations({
        name: 'name',
        value: selectedNation?.value === 'All' ?
          [] :
          selectedNation?.value === 'None' ?
            [''] :
            [selectedNation?.name]
      })
    )

    // If `All` or `None` Selected, Clear Region Select to Default
    if (selectedNation.value === 'All' || selectedNation.value === 'None') {
      // Clear All Region
      dispatch(setSelectedRegion({ value: 'None', label: 'None' }))
      dispatch(setRegions([]))
      dispatch(clearRegionsFromMap())

    } else {
      const brand = selectedBrand?.label && selectedBrand?.value !== 'None' && selectedBrand?.value !== 'All' ? selectedBrand.label : ''
      const sku = selectedSku?.label && selectedSku?.value !== 'None' && selectedSku?.value !== 'All' ? selectedSku.label : ''

      // Load Regions By Nation
      dispatch(getRegions(selectedNation, startDate, endDate, brand, sku))

      // Show All Regions
      const selectedRegion = { value: 'All', label: 'All' }
      dispatch(setSelectedRegion(selectedRegion))
      dispatch(
        filterNations({
          name: 'name',
          value: ['']
        })
      )
    }
  }

  // On selectedRegion Update in Props
  _onSelectedRegionUpdate = (selectedRegion, selectedNation) => {
    const { dispatch, startDate, endDate, selectedBrand, selectedSku } = this.props

    // Filter Regions Data
    dispatch(
      filterRegions({
        name: 'name',
        value: selectedRegion?.value === 'All' ?
          [] :
          selectedRegion?.value === 'None' ?
            [''] :
            [selectedRegion?.name]
      })
    )

    // If `All` or `None` Selected, Clear Area Select to Default
    if (selectedRegion.value === 'All' || selectedRegion.value === 'None') {
      // Clear All Areas
      dispatch(setSelectedArea({ value: 'None', label: 'None' }))
      dispatch(setAreas([]))
      dispatch(clearAreasFromMap())

      // If `None` Selected, Show Selected Nation
      if (selectedRegion.value === 'None') {
        // Filter Nations Data
        dispatch(
          filterNations({
            name: 'name',
            value: selectedNation?.value === 'All' ?
              [] :
              selectedNation?.value === 'None' ?
                [''] :
                [selectedNation?.name]
          })
        )
      }

      // If `All` Selected, Hide Selected Nation
      if (selectedRegion.value === 'All') {
        // Filter Nations Data
        dispatch(
          filterNations({
            name: 'name',
            value: ['']
          })
        )
      }

    } else {
      const brand = selectedBrand?.label && selectedBrand?.value !== 'None' && selectedBrand?.value !== 'All' ? selectedBrand.label : ''
      const sku = selectedSku?.label && selectedSku?.value !== 'None' && selectedSku?.value !== 'All' ? selectedSku.label : ''

      // Load Areas By Region
      dispatch(getAreas(selectedRegion, startDate, endDate, brand, sku))

      // Show All Areas
      const selectedArea = { value: 'All', label: 'All' }
      dispatch(setSelectedArea(selectedArea))
      dispatch(
        filterRegions({
          name: 'name',
          value: ['']
        })
      )
      dispatch(
        filterNations({
          name: 'name',
          value: ['']
        })
      )
    }
  }

  // On selectedArea Update in Props
  _onSelectedAreaUpdate = (selectedArea, selectedRegion) => {
    const { dispatch, startDate, endDate, selectedBrand, selectedSku } = this.props

    // Filter Areas Data
    dispatch(
      filterAreas({
        name: 'name',
        value: selectedArea?.value === 'All' ?
          [] :
          selectedArea?.value === 'None' ?
            [''] :
            [selectedArea?.name]
      })
    )

    // If `All` or `None` Selected, Clear Territory Select to Default
    if (selectedArea.value === 'All' || selectedArea.value === 'None') {
      // Clear All Territories & Hide Regions
      dispatch(setSelectedTerritory({ value: 'None', label: 'None' }))
      dispatch(setTerritories([]))
      dispatch(clearTerritoriesFromMap())

      // If `None` Selected, Show Selected Region
      if (selectedArea.value === 'None') {
        // Filter Regions Data
        dispatch(
          filterRegions({
            name: 'name',
            value: selectedRegion?.value === 'All' ?
              [] :
              selectedRegion?.value === 'None' ?
                [''] :
                [selectedRegion?.name]
          })
        )
      }

      // If `All` Selected, Hide Selected Region
      if (selectedArea.value === 'All') {
        // Filter Regions Data
        dispatch(
          filterRegions({
            name: 'name',
            value: ['']
          })
        )
      }

    } else {
      const brand = selectedBrand?.label && selectedBrand?.value !== 'None' && selectedBrand?.value !== 'All' ? selectedBrand.label : ''
      const sku = selectedSku?.label && selectedSku?.value !== 'None' && selectedSku?.value !== 'All' ? selectedSku.label : ''

      // Load Territories By Area
      dispatch(getTerritories(selectedArea, startDate, endDate, brand, sku))

      // Show All Territories && Hide Areas
      const selectedTerritory = { value: 'All', label: 'All' }
      dispatch(setSelectedTerritory(selectedTerritory))
      dispatch(
        filterAreas({
          name: 'name',
          value: ['']
        })
      )
      dispatch(
        filterRegions({
          name: 'name',
          value: ['']
        })
      )
    }
  }

  // On selectedTerritory Update in Props
  _onSelectedTerritoryUpdate = (selectedTerritory, selectedArea) => {
    const { dispatch, startDate, endDate, selectedBrand, selectedSku } = this.props

    // Filter Territory Data
    dispatch(
      filterTerritories({
        name: 'name',
        value: selectedTerritory?.value === 'All' ?
          [] :
          selectedTerritory?.value === 'None' ?
            [''] :
            [selectedTerritory?.name]
      })
    )

    // If `All` or `None` Selected, Clear Town Select to Default
    if (selectedTerritory.value === 'All' || selectedTerritory.value === 'None') {
      // Clear All Towns & Hide Areas
      dispatch(setSelectedTown({ value: 'None', label: 'None' }))
      dispatch(setTowns([]))
      dispatch(setDistributionHouses([]))
      dispatch(clearTownsFromMap())

      // If `None` Selected, Show Selected Area
      if (selectedTerritory.value === 'None') {
        // Filter Area Data
        dispatch(
          filterAreas({
            name: 'name',
            value: selectedArea?.value === 'All' ?
              [] :
              selectedArea?.value === 'None' ?
                [''] :
                [selectedArea?.name]
          })
        )
      }

      // If `All` Selected, Hide Selected Area
      if (selectedTerritory.value === 'All') {
        // Filter Area Data
        dispatch(
          filterAreas({
            name: 'name',
            value: ['']
          })
        )
      }

    } else {
      const brand = selectedBrand?.label && selectedBrand?.value !== 'None' && selectedBrand?.value !== 'All' ? selectedBrand.label : ''
      const sku = selectedSku?.label && selectedSku?.value !== 'None' && selectedSku?.value !== 'All' ? selectedSku.label : ''

      // Load Towns By Territory
      dispatch(getTowns(selectedTerritory, startDate, endDate, brand, sku))

      // Show All Towns && Hide Territories
      const selectedTown = { value: 'All', label: 'All' }
      dispatch(setSelectedTown(selectedTown))
      dispatch(
        filterTerritories({
          name: 'name',
          value: ['']
        })
      )
      dispatch(
        filterAreas({
          name: 'name',
          value: ['']
        })
      )
    }
  }

  // On selectedTown Update in Props
  _onSelectedTownUpdate = (selectedTown, selectedTerritory) => {
    const { dispatch, startDate, endDate, selectedBrand, selectedSku, isFixedRoutesOn } = this.props

    // Filter Towns Data
    dispatch(
      filterTowns({
        name: 'name',
        value: selectedTown?.value === 'All' ?
          [] :
          selectedTown?.value === 'None' ?
            [''] :
            [selectedTown?.name]
      })
    )

    // If `All` or `None` Selected, Clear Route Select to Default
    if (selectedTown.value === 'All' || selectedTown.value === 'None') {
      // Clear All Routes & Hide Territories
      dispatch(setSelectedRoute({ value: 'None', label: 'None' }))
      dispatch(setRoutes([]))
      dispatch(clearRoutesFromMap())

      // If `None` Selected, Show Selected Territory
      if (selectedTown.value === 'None') {
        // Filter Territories Data
        dispatch(
          filterTerritories({
            name: 'name',
            value: selectedTerritory?.value === 'All' ?
              [] :
              selectedTerritory?.value === 'None' ?
                [''] :
                [selectedTerritory?.name]
          })
        )
      }

      // If `All` Selected, Hide Selected Territory
      if (selectedTown.value === 'All') {
        // Filter Territory Data
        dispatch(
          filterTerritories({
            name: 'name',
            value: ['']
          })
        )
      }

    } else {
      const brand = selectedBrand?.label && selectedBrand?.value !== 'None' && selectedBrand?.value !== 'All' ? selectedBrand.label : ''
      const sku = selectedSku?.label && selectedSku?.value !== 'None' && selectedSku?.value !== 'All' ? selectedSku.label : ''

      // Load Routes By Town
      dispatch(getRoutes(selectedTown, startDate, endDate, brand, sku, isFixedRoutesOn))

      // Show All Routes && Hide Territories
      const selectedRoute = { value: 'All', label: 'All' }
      dispatch(setSelectedRoute(selectedRoute))
      dispatch(
        filterTowns(
          {
            name: 'name',
            value: ['']
          },
          { persistDistributionHouse: true }
        )
      )
      dispatch(
        filterTerritories({
          name: 'name',
          value: ['']
        })
      )
    }
  }

  // On selectedRoute Update in Props
  _onSelectedRouteUpdate = (selectedRoute, selectedTown) => {
    const { dispatch, startDate, endDate, selectedBrand, selectedSku, lateOrderThresholdTime, fastOrderDuration } = this.props

    // Filter Route Data
    dispatch(
      filterRoutes({
        name: 'name',
        value: selectedRoute?.value === 'All' ?
          [] :
          selectedRoute?.value === 'None' ?
            [''] :
            [selectedRoute?.name]
      })
    )

    // If `All` or `None` Selected, Clear Outlets Data
    if (selectedRoute.value === 'All' || selectedRoute.value === 'None') {
      // Clear All Outlets & Hide Towns
      dispatch(clearOutletsFromMap())
      dispatch(setOutlets([]))

      // If `None` Selected, Show Selected Towns
      if (selectedRoute.value === 'None') {
        // Filter Towns Data
        dispatch(
          filterTowns({
            name: 'name',
            value: selectedTown?.value === 'All' ?
              [] :
              selectedTown?.value === 'None' ?
                [''] :
                [selectedTown?.name]
          })
        )
      }

      // If `All` Selected, Show Selected Town
      if (selectedRoute.value === 'All') {
        // Filter Towns Data
        dispatch(
          filterTowns(
            {
              name: 'name',
              value: ['']
            },
            { persistDistributionHouse: true }
          )
        )
      }

    } else {
      const brand = selectedBrand?.label && selectedBrand?.value !== 'None' && selectedBrand?.value !== 'All' ? selectedBrand.label : ''
      const sku = selectedSku?.label && selectedSku?.value !== 'None' && selectedSku?.value !== 'All' ? selectedSku.label : ''

      // Load Outlets By Route
      dispatch(
        getOutlets({ route_id: selectedRoute?.id ?? '', start_date: startDate, end_date: endDate, brand, sku, late_hour: lateOrderThresholdTime, fast_order_duration: fastOrderDuration })
      )

      // Hide Towns
      dispatch(
        filterTowns(
          {
            name: 'name',
            value: ['']
          },
          { persistDistributionHouse: true }
        )
      )
    }
  }

  // On Selected Outlets Filter Option Change
  _selectedOutletsFilterOptionChange = selectedOutletsFilterOptions => {
    const { dispatch } = this.props
    dispatch(setSelectedOutletsFilterOption(selectedOutletsFilterOptions))
  }

  // On Selected Outlets Filter Option Update in props
  _onSelectedOutletsFilterOptionUpdate = selectedOutletsFilterOption => {
    const { dispatch, filters } = this.props
    const availableFilters = ['is_late_order', 'is_fast_order', 'is_declining', 'is_compliant', 'is_zero_sales']

    // Clear Outlet Filters on Left Nav
    filters.forEach((f, i) => {
      if (f.dataId.includes(OUTLETS.DATA_ID) && availableFilters.includes(f.name.length ? f.name[0] : '')) {
        dispatch(setFilter(i, 'value', [0, 1]))
      }
    })

    if (selectedOutletsFilterOption?.value === 'late-order-outlets') {
      // Filter Outlets by Late Order Outlets
      dispatch(
        filterOutlets({
          name: 'is_late_order',
          value: [1, 1]
        })
      )

      return
    }

    if (selectedOutletsFilterOption?.value === 'fast-order-outlets') {
      // Filter Outlets by Fast Order Outlets
      dispatch(
        filterOutlets({
          name: 'is_fast_order',
          value: [1, 1]
        })
      )

      return
    }

    if (selectedOutletsFilterOption?.value === 'declining-outlets') {
      // Filter Outlets by Declining
      dispatch(
        filterOutlets({
          name: 'is_declining',
          value: [1, 1]
        })
      )

      return
    }

    if (selectedOutletsFilterOption?.value === 'non-compliant-outlets') {
      // Filter Outlets by Non Compliant
      dispatch(
        filterOutlets({
          name: 'is_compliant',
          value: [0, 0]
        })
      )

      return
    }

    if (selectedOutletsFilterOption?.value === 'zero-sales-outlets') {
      // Filter Outlets by Zero Sales
      dispatch(
        filterOutlets({
          name: 'is_zero_sales',
          value: [1, 1]
        })
      )

      return
    }
  }

  render() {
    const { isNavLoading, nations, selectedNation, regions, selectedRegion, areas, selectedArea, territories, selectedTerritory, towns, selectedTown, routes, selectedRoute, isOutletsListExpanded, isNationOn, isRegionOn, isAreaOn, isTerritoryOn, isTownOn, isRouteOn, isSrOrdersLayerOn, srVisitedOutlets, outletsFilterOptions, selectedOutletsFilterOption, selectedRouteStatsType, selectedTownStatsType, datasets } = this.props
    const { tempOutlets } = this.state

    return (
      <Box sx={containerStyles}>
        <Box sx={bodyContainerStyles}>
          {(isNationOn || (!isNationOn && isRegionOn)) &&
            <StyledSelect
              label='Select Nation'
              name='select-nation'
              value={selectedNation?.value ?? ''}
              onChange={this._onNationChange}
              options={nations}
              disabled={isNavLoading || !isNationOn}
            />
          }

          {(isRegionOn || (!isRegionOn && isAreaOn)) &&
            <StyledSelect
              label='Select Region'
              name='select-region'
              value={selectedRegion?.value ?? ''}
              onChange={this._onRegionChange}
              options={regions}
              disabled={
                isNavLoading || !selectedNation || !selectedNation?.value || selectedNation?.value === 'All' || selectedNation?.value === 'None' || !isRegionOn
              }
            />
          }

          {(isAreaOn || (!isAreaOn && isTerritoryOn)) &&
            <StyledSelect
              label='Select Area'
              name='select-area'
              value={selectedArea?.value ?? ''}
              onChange={this._onAreaChange}
              options={areas}
              disabled={
                isNavLoading || !selectedRegion || !selectedRegion?.value || selectedRegion?.value === 'All' || selectedRegion?.value === 'None' || !isAreaOn
              }
            />
          }

          {(isTerritoryOn || (!isTerritoryOn && isTownOn)) &&
            <StyledSelect
              label='Select Territory'
              name='select-territory'
              value={selectedTerritory?.value ?? ''}
              onChange={this._onTerritoryChange}
              options={territories}
              disabled={
                isNavLoading || !selectedArea || !selectedArea?.value || selectedArea?.value === 'All' || selectedArea?.value === 'None' || !isTerritoryOn
              }
            />
          }

          {(isTownOn || (!isTownOn && isRouteOn)) &&
            <StyledSelect
              label='Select Town'
              name='select-town'
              value={selectedTown?.value ?? ''}
              onChange={this._onTownChange}
              options={towns}
              disabled={
                isNavLoading || !selectedTerritory || !selectedTerritory?.value || selectedTerritory?.value === 'All' || selectedTerritory?.value === 'None' || !isTownOn
              }
            />
          }

          <StyledSelect
            label='Select Route'
            name='select-route'
            value={selectedRoute?.value ?? ''}
            onChange={this._onRouteChange}
            options={routes}
            disabled={
              isNavLoading || !selectedTown || !selectedTown?.value || selectedTown?.value === 'All' || selectedTown?.value === 'None' || !isRouteOn
            }
          />

          <Divider sx={{ my: '0.5rem' }} />

          <StatsSelect />

          {(selectedTown?.value && selectedTown?.value !== 'None' && selectedTown?.value !== 'All') &&
            <React.Fragment>
              <Divider sx={{ my: '0.5rem' }} />
              <StyledToggleButtonGroup
                value={selectedOutletsFilterOption?.value ?? ''}
                onChange={this._selectedOutletsFilterOptionChange}
                buttonOptions={outletsFilterOptions}
                disabled={selectedTown?.value === 'None' || selectedTown?.value === 'All' || selectedRouteStatsType !== 'route' || selectedTownStatsType !== 'town'}
              />
              <Accordion
                variant='outlined'
                elevation={0}
                disableGutters={true}
                expanded={isOutletsListExpanded}
                onChange={this._onOutletsListToggle}
                square={true}
                sx={{
                  '&::before': {
                    height: 0
                  }
                }}
              >
                <AccordionSummary
                  expandIcon={<ExpandMore fontSize='small' />}
                  sx={{
                    color: 'text.secondary',
                    '&.MuiButtonBase-root': {
                      minHeight: '24px'
                    },
                    '& .MuiAccordionSummary-content': {
                      my: '4px'
                    }
                  }}
                >
                  <Typography
                    color='text.secondary'
                    fontSize='0.8rem'
                  >
                    {`${isSrOrdersLayerOn && srVisitedOutlets?.length ? 'Order Outlets' : selectedOutletsFilterOption?.label ?? ''} (${isSrOrdersLayerOn && srVisitedOutlets?.length ? srVisitedOutlets?.length ?? 0 : datasets[OUTLETS.DATA_ID]?.filteredIndexForDomain?.length ?? 0})`}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails sx={{ p: 0 }}>
                  <OutletList
                    outlets={isSrOrdersLayerOn && srVisitedOutlets?.length ? srVisitedOutlets : tempOutlets}
                  />
                </AccordionDetails>
              </Accordion>
            </React.Fragment>
          }
        </Box>
      </Box>
    )
  }
}

// Styles
const containerStyles = {
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'space-between'
}

const bodyContainerStyles = theme => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'space-between',
  padding: theme.spacing(1),
  paddingX: theme.spacing(2),
  rowGap: theme.spacing(0.5)
})

// Prop Types
LeftNavBody.propTypes = {
  isNavLoading: PropTypes.bool,
  nations: PropTypes.array,
  selectedNation: PropTypes.object,
  regions: PropTypes.array,
  selectedRegion: PropTypes.object,
  areas: PropTypes.array,
  selectedArea: PropTypes.object,
  territories: PropTypes.array,
  selectedTerritory: PropTypes.object,
  towns: PropTypes.array,
  selectedTown: PropTypes.object,
  routes: PropTypes.array,
  selectedRoute: PropTypes.object,
  outlets: PropTypes.array,
  isOutletsListExpanded: PropTypes.bool,
  datasets: PropTypes.object,
  layers: PropTypes.array,
  layerData: PropTypes.array,
  startDate: PropTypes.string,
  endDate: PropTypes.string,
  selectedBrand: PropTypes.object,
  selectedSku: PropTypes.object,
  isNationOn: PropTypes.bool,
  isRegionOn: PropTypes.bool,
  isAreaOn: PropTypes.bool,
  isTerritoryOn: PropTypes.bool,
  isTownOn: PropTypes.bool,
  isRouteOn: PropTypes.bool,
  isSrOrdersLayerOn: PropTypes.bool,
  srVisitedOutlets: PropTypes.array,
  outletsFilterOptions: PropTypes.array,
  selectedOutletsFilterOption: PropTypes.object,
  lateOrderOutlets: PropTypes.array,
  fastOrderOutlets: PropTypes.array,
  decliningOutlets: PropTypes.array,
  nonCompliantOutlets: PropTypes.array,
  lateOrderThresholdTime: PropTypes.string,
  selectedRouteStatsType: PropTypes.string,
  selectedTownStatsType: PropTypes.string,
  fastOrderDuration: PropTypes.number,
  isFixedRoutesOn: PropTypes.bool,
  filters: PropTypes.array
}

LeftNavBody.defaultProps = {
  isNavLoading: false,
  nations: [],
  selectedNation: null,
  regions: [],
  selectedRegion: null,
  areas: [],
  selectedArea: null,
  territories: [],
  selectedTerritory: null,
  towns: [],
  selectedTown: null,
  routes: [],
  selectedRoute: null,
  outlets: [],
  isOutletsListExpanded: false,
  datasets: {},
  layers: [],
  layerData: [],
  startDate: '',
  endDate: '',
  selectedBrand: null,
  selectedSku: null,
  isNationOn: true,
  isRegionOn: true,
  isAreaOn: true,
  isTerritoryOn: true,
  isTownOn: true,
  isRouteOn: true,
  isSrOrdersLayerOn: false,
  srVisitedOutlets: [],
  outletsFilterOptions: [],
  selectedOutletsFilterOption: null,
  lateOrderOutlets: [],
  fastOrderOutlets: [],
  decliningOutlets: [],
  nonCompliantOutlets: [],
  lateOrderThresholdTime: '17:00:00',
  selectedRouteStatsType: 'route',
  selectedTownStatsType: 'town',
  fastOrderDuration: 120,
  isFixedRoutesOn: false,
  filters: []
}

const mapStateToProps = state => ({
  isNavLoading: state?.nav?.isNavLoading ?? false,
  nations: state?.nav?.nations ?? [],
  selectedNation: state?.nav?.selectedNation ?? null,
  regions: state?.nav?.regions ?? [],
  selectedRegion: state?.nav?.selectedRegion ?? null,
  areas: state?.nav?.areas ?? [],
  selectedArea: state?.nav?.selectedArea ?? null,
  territories: state?.nav?.territories ?? [],
  selectedTerritory: state?.nav?.selectedTerritory ?? null,
  towns: state?.nav?.towns ?? [],
  selectedTown: state?.nav?.selectedTown ?? null,
  routes: state?.nav?.routes ?? [],
  selectedRoute: state?.nav?.selectedRoute ?? null,
  outlets: state?.nav?.outlets ?? [],
  isOutletsListExpanded: state?.nav?.isOutletsListExpanded ?? false,
  datasets: state?.keplerGl?.map?.visState?.datasets ?? {},
  layers: state?.keplerGl?.map?.visState?.layers ?? [],
  layerData: state?.keplerGl?.map?.visState?.layerData ?? [],
  startDate: state?.stat?.startDate ?? '',
  endDate: state?.stat?.endDate ?? '',
  selectedBrand: state?.stat?.selectedBrand ?? null,
  selectedSku: state?.stat?.selectedSku ?? null,
  isNationOn: state?.featureControl?.isNationOn ?? true,
  isRegionOn: state?.featureControl?.isRegionOn ?? true,
  isAreaOn: state?.featureControl?.isAreaOn ?? true,
  isTerritoryOn: state?.featureControl?.isTerritoryOn ?? true,
  isTownOn: state?.featureControl?.isTownOn ?? true,
  isRouteOn: state?.featureControl?.isRouteOn ?? true,
  isSrOrdersLayerOn: state?.stat?.isSrOrdersLayerOn ?? false,
  srVisitedOutlets: state?.stat?.srVisitedOutlets ?? [],
  outletsFilterOptions: state?.nav?.outletsFilterOptions ?? [],
  selectedOutletsFilterOption: state?.nav?.selectedOutletsFilterOption ?? null,
  lateOrderOutlets: state?.nav?.lateOrderOutlets ?? [],
  fastOrderOutlets: state?.nav?.fastOrderOutlets ?? [],
  decliningOutlets: state?.nav?.decliningOutlets ?? [],
  nonCompliantOutlets: state?.nav?.nonCompliantOutlets ?? [],
  lateOrderThresholdTime: state?.configs?.lateOrderThresholdTime ?? '17:00:00',
  selectedRouteStatsType: state?.stat?.selectedRouteStatsType ?? 'route',
  selectedTownStatsType: state?.stat?.selectedTownStatsType ?? 'town',
  fastOrderDuration: state?.configs?.fastOrderDuration ?? 120,
  isFixedRoutesOn: state?.nav?.isFixedRoutesOn ?? false,
  filters: state?.keplerGl?.map?.visState?.filters ?? []
})

const mapDispatchToProps = dispatch => ({ dispatch })

export default connect(mapStateToProps, mapDispatchToProps)(LeftNavBody)